//libs
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import "react-router-modal/css/react-router-modal.css";
import Lottie from "react-lottie";
import * as animationData from "../animation.json";

//images
import stipplThumbnail from "../img/cases/stippl/thumbnail.png";
import gnThumbnail from "../img/cases/gn/gn-thumbnail.png";
import regtechThumbnail from "../img/cases/regtech/regtech-thumbnail.png";
import stipplLogo from "../img/cases/stippl/logo_fullcolor.svg";
import gnLogo from "../img/cases/gn/logo.svg";
import Stippl from "./cases/Stippl.jsx";
import RegTech from "./cases/RegTech";
import GN from "./cases/GN";
import CallToAction from "./CallToAction";
import OmarHeadshot from "../img/omar_headshot.png";

import fullLogo from "../img/logo_full.svg";
import O from "../img/O.svg";
import M from "../img/M.svg";
import A from "../img/A.svg";
import R from "../img/R.svg";

import Icon from "./Icons";

function Homepage() {
  const [loading, setLoading] = useState(true);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const eventListeners = [
    {
      eventName: "complete",
      callback: () => setLoading(false)
    }
  ];
  return (
    <div className="container">
      <div className={loading ? `loader true` : "loader false"}>
        <h3>Solving puzzle..</h3>
        <Lottie
          options={defaultOptions}
          height={150}
          width={300}
          eventListeners={eventListeners}
        />
      </div>
      <section className="intro">
        <div className="headshot">
          <img src={OmarHeadshot} alt="omar-picture" />
          <div className="outer-border"></div>
        </div>
        <div className="headline">
          {/*<div className="header">
            
             <nav>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/omarsheshtawy/"
                    target="_blank"
                  >
                    <Icon name="linkedin" />
                  </a>
                </li>
                <li className="mail">
                  <a href="mailto:omar@uxp.design">
                    <Icon name="mail" />
                  </a>
                </li>
              </ul>
            </nav> 
          </div>
          */}

          <div className="logo">
            <img src={fullLogo} alt="Omar Sheshtawy" />
          </div>
          <h1>
            A digital designer that loves to solve complex product puzzles.
          </h1>
          <div className="actions">
            <a href="mailto:omar@uxp.design" className="circle-action">
              <Icon name="mail" />
            </a>
            <a
              href="https://www.linkedin.com/in/omarsheshtawy/"
              target="_blank"
              className="circle-action"
            >
              <Icon name="linkedin" />
            </a>
            <div className="scroll-trigger">
              <Icon name="scroll" />
              <span>View work</span>
            </div>
          </div>
        </div>
        <div className="shapes">
          <img className="O" src={O} alt="O" />
          <img className="M" src={M} alt="M" />
          <img className="A" src={A} alt="A" />
          <img className="R" src={R} alt="R" />
        </div>
      </section>
      <section className="work">
        <header>
          <h2>Cases</h2>
          <p>A selection of cases I've worked on</p>
        </header>
        <div className="work-list">
          <article className="case">
            <div className="image">
              <div className="image-background">
                <img src={stipplThumbnail} alt="Stippl Image" />
              </div>
            </div>
            <div className="case-information">
              <header>
                <img src={stipplLogo} alt="Stippl Logo" />
                <ul className="tags">
                  <li>Concept</li>
                  <li>Research</li>
                  <li>UX / UI Design</li>
                  <li>Front-end Development</li>
                  <li>Product owner</li>
                </ul>
              </header>
              <div className="introduction">
                <h3>Adventure planning made easy</h3>
                <p>
                  Stippl is the all-in-one adventure planner designed to help
                  you plan destinations, accommodations, things to do and
                  transportation.
                </p>
                <Link to="/stippl" className="view-case">
                  <div className="circle-action">
                    <Icon name="arrow" />
                  </div>
                  View the case
                </Link>
              </div>
            </div>
          </article>

          <article className="case">
            <div className="image">
              <div className="image-background regtech-case">
                <img src={regtechThumbnail} alt="RegTech Image" />
              </div>
            </div>
            <div className="case-information">
              <header>
                <h2>RegTech solution for a big corporate</h2>
                <ul className="tags">
                  <li>UX Design</li>
                  <li>UI Design</li>
                </ul>
              </header>
              <div className="introduction">
                <p>
                  A RegTech solution to implement external regulations more
                  efficiently within organizations.
                </p>
                <Link to="/regtech" className="view-case">
                  <div className="circle-action">
                    <Icon name="arrow" />
                  </div>
                  View the case
                </Link>
              </div>
            </div>
          </article>

          <article className="case ">
            <div className="image">
              <div className="image-background gn-case">
                <img src={gnThumbnail} alt="GN Image" />
              </div>
            </div>
            <div className="case-information">
              <header>
                <img src={gnLogo} alt="Stippl Logo" />
                <ul className="tags">
                  <li>UX Design</li>
                  <li>UI Design</li>
                </ul>
              </header>
              <div className="introduction">
                <h3>Data-driven financial insights for SMEs</h3>
                <p>
                  Growth Navigator is an online tool to help SMEs manage cash,
                  gain insights to solve liquidity squeeze issues and execute
                  structural improvements to optimize working capital.
                </p>
                <Link to="/growthnavigator" className="view-case">
                  <div className="circle-action">
                    <Icon name="arrow" />
                  </div>
                  View the case
                </Link>
              </div>
            </div>
          </article>
        </div>
      </section>
      <CallToAction
        title="Want to work together?"
        text="I'm always in for a chat, so don't hesitate and drop me a message!"
      />

      <ModalRoute
        component={Stippl}
        path="/stippl"
        className="react-router-modal__modal case-modal"
        outDelay={300}
        parentPath="/"
      />

      <ModalRoute
        component={RegTech}
        path="/regtech"
        className="react-router-modal__modal case-modal"
        outDelay={300}
        parentPath="/"
      />
      <ModalRoute
        component={GN}
        path="/growthnavigator"
        className="react-router-modal__modal case-modal"
        outDelay={300}
        parentPath="/"
      />
      {/* <Route path={`${url}/modal_link`} component={ModalLinkExample} />
          <Route path={`${url}/match_params`} component={MatchParamsExample} />
          <Route path={url} component={Home} exact />
          <Route path="*" component={NotFound} /> */}
    </div>
  );
}

export default Homepage;
