import React from "react";
import logo from "../img/logo.svg";
import Icon from "./Icons";
const CallToAction = ({ title, text }) => {
  return (
    <>
      <div className="cta">
        <img src={logo} alt="logo" />
        <h3>{title}</h3>
        <p>{text}</p>
        <div className="actions">
          <a href="mailto:omar@uxp.design" className="circle-action inversed">
            <Icon name="mail" />
          </a>
          <a
            href="https://www.linkedin.com/in/omarsheshtawy/"
            target="_blank"
            className="circle-action inversed"
          >
            <Icon name="linkedin" />
          </a>
        </div>
      </div>
    </>
  );
};

export default CallToAction;
