import React from "react";

const icons = {
  // GENERAL ICONS
  linkedin: [
    "M921.61 921.6v-300.032c0-147.456-31.744-260.096-203.776-260.096-82.944 0-138.24 45.056-160.768 88.064h-2.048v-74.752h-162.818v546.816h169.986v-271.36c0-71.68 13.312-140.288 101.376-140.288 87.040 0 88.064 80.896 88.064 144.384v266.24h169.984v1.024z",
    "M115.7 374.785h169.984v546.815h-169.984v-546.815z",
    "M200.704 102.4c-54.272 0-98.304 44.032-98.304 98.304s44.032 99.328 98.304 99.328c54.272 0 98.304-45.056 98.304-99.328s-44.032-98.304-98.304-98.304v0z"
  ],
  whatsapp: [
    "M851.604 171.012c-89.81-89.918-209.254-139.459-336.502-139.512-262.209 0-475.607 213.393-475.713 475.67-0.035 83.842 21.867 165.685 63.498 237.826l-67.488 246.502 252.182-66.15c69.486 37.903 147.715 57.876 227.326 57.902h0.2c262.175 0 475.597-213.412 475.699-475.699 0.051-127.113-49.382-246.627-139.203-336.539zM515.103 902.912h-0.164c-70.947-0.031-140.527-19.098-201.242-55.112l-14.432-8.576-149.648 39.26 39.944-145.905-9.404-14.961c-39.58-62.95-60.481-135.711-60.445-210.42 0.082-217.998 177.457-395.355 395.551-395.355 105.605 0.035 204.882 41.215 279.532 115.951s115.732 174.076 115.697 279.727c-0.092 218.013-177.454 395.39-395.387 395.39v0zM731.981 606.787c-11.884-5.955-70.323-34.698-81.224-38.671-10.885-3.968-18.821-5.944-26.737 5.949-7.926 11.894-30.705 38.671-37.642 46.602s-13.86 8.929-25.748 2.975c-11.889-5.944-50.187-18.504-95.591-58.998-35.332-31.519-59.185-70.441-66.123-82.335-6.926-11.907-0.058-17.719 5.215-24.258 12.867-15.978 25.752-32.73 29.713-40.658 3.967-7.934 1.98-14.877-0.996-20.825-2.965-5.947-26.737-64.453-36.639-88.26-9.656-23.168-19.447-20.039-26.748-20.402-6.926-0.346-14.854-0.416-22.781-0.416-7.922 0-20.801 2.971-31.699 14.877-10.892 11.9-41.595 40.652-41.595 99.158s42.592 115.026 48.533 122.957c5.941 7.936 83.818 127.995 203.052 179.476 28.36 12.257 50.493 19.564 67.763 25.042 28.477 9.047 54.38 7.767 74.865 4.71 22.84-3.415 70.318-28.759 80.23-56.52 9.907-27.766 9.907-51.564 6.927-56.525-2.964-4.956-10.89-7.931-22.774-13.88v0z"
  ],
  mail: [
    "M987.409 146.286h-950.854l475.429 391.607 480.8-390.51c-1.758-0.514-3.556-0.881-5.376-1.097z",
    "M535.075 613.518c-13.474 11.036-32.861 11.036-46.335 0l-488.74-402.648v630.272c0 20.196 16.374 36.574 36.572 36.574h950.854c20.201 0 36.574-16.378 36.574-36.574v-624.86l-488.925 397.236z"
  ],
  arrow: [
    "M64.126 29.992c39.99-39.99 104.826-39.99 144.815 0l409.6 409.6c39.99 39.99 39.99 104.826 0 144.815l-409.6 409.603c-39.99 39.984-104.826 39.984-144.815 0-39.99-39.991-39.99-104.83 0-144.821l337.192-337.19-337.192-337.192c-39.99-39.99-39.99-104.826 0-144.815z"
  ],
  close: [
    "M975.908 48.094c29.988 29.992 29.988 78.619 0 108.612l-819.202 819.202c-29.992 29.988-78.619 29.988-108.612 0-29.992-29.993-29.992-78.623 0-108.616l819.198-819.198c29.993-29.992 78.623-29.992 108.616 0z",
    "M48.094 48.094c-29.992 29.992-29.992 78.619 0 108.612l819.198 819.202c29.993 29.988 78.623 29.988 108.616 0 29.988-29.993 29.988-78.623 0-108.616l-819.202-819.198c-29.992-29.992-78.619-29.992-108.612 0z"
  ],
  scroll: [
    "M511.202 0c-166.085 0-301.202 135.117-301.202 301.203v421.591c0 166.089 135.117 301.205 301.202 301.205 166.089 0 301.205-135.117 301.205-301.205v-421.591c0-166.086-135.117-301.203-301.205-301.203zM750.967 722.795c0 132.207-107.563 239.77-239.765 239.77s-239.764-107.563-239.764-239.77v-421.591c0-132.203 107.562-239.766 239.764-239.766s239.765 107.563 239.765 239.766v421.591z",
    "M511.219 203.703c-16.969 0-30.72 13.75-30.72 30.719v141.391c0 16.961 13.751 30.719 30.72 30.719 16.96 0 30.72-13.758 30.72-30.719v-141.391c0-16.969-13.751-30.719-30.72-30.719z"
  ]
};

const Icon = ({ name, classname = "", fill = "" }) => (
  <svg viewBox="0 0 1024 1024" className={classname} fill={fill}>
    {icons[name]?.map(path => <path key={path} d={path}></path>)}
  </svg>
);

export default Icon;
