import { Link } from "react-router-dom";
import Icon from "../Icons";
import CallToAction from "../CallToAction";

//images
import headerImage from "../../img/cases/regtech/createrequirement.png";
import assignmember from "../../img/cases/regtech/assignmember.png";
import connections from "../../img/cases/regtech/connections.png";
import createobligation from "../../img/cases/regtech/createobligation.png";
import createpolicy from "../../img/cases/regtech/createpolicy.png";
import dashboard from "../../img/cases/regtech/dashboard.png";
import horizonscanning from "../../img/cases/regtech/horizonscanning.png";
import requirementslist from "../../img/cases/regtech/requirementslist.png";
import sounding from "../../img/cases/regtech/sounding.png";

function RegTech() {
  return (
    <div className="case-container">
      <Link to="/" className="close">
        <Icon name="close" />
      </Link>
      <div className="case-introduction">
        <div className="intro-wrapper regtech-case">
          <article>
            <h1>RegTech solution for a big corporate</h1>
            <p>
              A RegTech solution to implement external regulations more
              efficiently within organizations. It provides a central and single
              point of access for all regulations in scope, creating structure
              and transparency in the process of managing policies and risk
              control.
            </p>
          </article>
        </div>
        <div className="header-image">
          <div className="image-wrapper">
            <figure>
              <img src={headerImage} />
            </figure>
          </div>
        </div>
      </div>
      <section>
        <article>
          <h2>The big challenges</h2>
          <p>
            During this project I worked as UX and UI designer together with
            design agency Eli5 and service designers in the core team. I was
            involved early on in the project to draft proof of concepts of one
            the biggest and most complicated applications I’ve worked on.
          </p>
          <p>
            I solved a lot of uncommon and complex UX challenges like tagging
            and defining data objects out of plain text documents, delta
            management of documents, a flexible policy builder, organizing all
            of these different parts within the application and much more. Next
            to that the application had to accommodate a lot of different users
            working together on creating a policy, which required
            functionalities like approval flows, commenting and user
            suggestions.
          </p>
          <p>
            A very complicated product of which I will just show a very little
            part below, starting with some screen prints of defining a data
            object and list overviews of the defined objects.
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <figcaption>
            1.0 Mark and create requirements from documents
          </figcaption>
          <img src={headerImage} />
        </figure>

        <figure>
          <figcaption>1.1 Viewing requirements</figcaption>
          <img src={requirementslist} />
        </figure>

        <figure>
          <figcaption>
            1.2 Creating an obligation based on created requirements
          </figcaption>
          <img src={createobligation} />
        </figure>
      </div>
      <section>
        <article>
          <h2>Policy builder</h2>
          <p>
            Defining all the data objects is just pre-work before the actual
            policy can be build. For this we designed a flexible policy builder,
            in which employees can write the full policy, based on the defined
            data objects.
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <figcaption>2.0 Building the policy structure</figcaption>
          <img src={createpolicy} />
        </figure>

        <figure>
          <figcaption>2.1 Working together on policy elements</figcaption>
          <img src={sounding} />
        </figure>
      </div>
      <section>
        <article>
          <h2>Dashboards and overviews</h2>
          <p>
            Every project has a dashboad that shows the status of al the
            different data objects and pending tasks and notifications. This
            data can be visualized in different ways to also see relationships
            between data objects.
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <figcaption>3.0 Main dashboard</figcaption>
          <img src={dashboard} />
        </figure>

        <figure>
          <figcaption>3.1 Connections between data objects</figcaption>
          <img src={connections} />
        </figure>

        <figure>
          <figcaption>3.2 Assigning data objects to employees</figcaption>
          <img src={assignmember} />
        </figure>
      </div>
      <CallToAction
        title="There’s much more.."
        text="There is much more to show and talk about! If you are interested in more details, drop me a message."
      />
    </div>
  );
}

export default RegTech;
