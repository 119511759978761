import { Link } from "react-router-dom";
import stipplLogo from "../../img/cases/stippl/logo_fullcolor.svg";
import Icon from "../Icons";

//images
import headerImage from "../../img/cases/stippl/core.png";
import accommodations from "../../img/cases/stippl/accommodations.png";
import poc from "../../img/cases/stippl/poc.png";
import searchTransport from "../../img/cases/stippl/searchtransport.png";
import sketches from "../../img/cases/stippl/sketches.png";
import todo from "../../img/cases/stippl/todo.png";
import transportDetail from "../../img/cases/stippl/transportdetail.png";
import research from "../../img/cases/stippl/research.png";
import CallToAction from "../CallToAction";

function Stippl() {
  return (
    <div className="case-container">
      <Link to="/" className="close">
        <Icon name="close" />
      </Link>
      <div className="case-introduction">
        <div className="intro-wrapper stippl-case">
          <article>
            <div className="logo">
              <img src={stipplLogo} alt="Stippl-logo" />
            </div>
            <h1>The all in one adventure planner</h1>
            <p>
              Stippl is the all-in-one adventure planner designed to help you
              plan destinations, accommodations, things to do and
              transportation. Stippl is the all-in-one adventure planner
              designed to help you plan destinations, accommodations, things to
              do and transportation.
            </p>
          </article>
        </div>
        <div className="header-image">
          <div className="image-wrapper">
            <figure>
              <img src={headerImage} />
            </figure>
          </div>
        </div>
      </div>
      <section>
        <article>
          <h2>My role in the project.</h2>
          <p>
            This project originated from a personal struggle and grew to be a
            very important side project, currently being worked on by a team of
            3 people. Within this project I’m responsible for concepting, the
            user experience, Interface design, front-end development and project
            management.
          </p>
          <p>
            It has been a project where I’ve been able to use all of my skills
            to the fullest and that has given me an enormous amount of energy.
          </p>
        </article>
        <article>
          <h2>The planning struggle.</h2>
          <p>
            Everyone knows the hassle of planning a new trip. Endless Google
            searches, complex planning, and a lot of other factors to take in to
            account, which cause an abundance of browser tabs and headaches to
            plan your trip.
          </p>
          <p>
            In May 2018 I encountered this struggle myself when planning a
            roundtrip through Indonesia for 3 weeks. No tool could help me to
            easily plan the amount of days I wanted to stay somewhere, to
            subsequently give me the total amount of days I had left to plan. I
            was a bit frustrated and so entered the (painful) journey of
            planning my trip with some notes on my phone.
          </p>
          <p>
            I knew there was something here, so I started talking with other
            travellers and investigated how they plan their travels and the
            results were painful, although not always recognized as such.
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <img src={research} />
        </figure>
      </div>
      <section>
        <article>
          <h2>Building an all-in-one adventure planner is pretty hard.</h2>
          <p>
            As we build our first proof of concept and talked with more
            travellers it became more and more clear that this was not going to
            be an easy job. Every traveller plans different and for each
            functionality in the planner there are countless edge cases to take
            in to account.
          </p>
          <p>
            We build a first version of the planner with a huge map, fancy cards
            for each destination in the overview and a detail view per
            destination to manage accommodations, activities and transportation.
            We went through countless iterations and improvements based on user
            feedback and finally based our MVP on the table structure the users
            now use in excel.
          </p>
          <p>
            The main thing here was that travellers wanted to have one overview
            of the full (as much as possible) trip and start planning from
            there. Although maybe not the most fancy, switching the interface
            from cards and a huge map to a table view with a smaller map was a
            huge improvement for the user experience.
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <figcaption>1.0 First sketch - ever</figcaption>
          <img src={sketches} />
        </figure>

        <figure>
          <figcaption>1.1 First design</figcaption>
          <img src={poc} />
        </figure>

        <figure>
          <figcaption>1.2 Many iterations later, MVP design</figcaption>
          <img src={headerImage} />
        </figure>
      </div>
      <section>
        <article>
          <h2>The MVP solution </h2>
          <p>
            One of the biggest challenge for this project has been (and still
            is) scoping of functionalities. Building an MVP for an all-in-one
            planner comes with a lot of core functionalities, but also a lot of
            possible (future) functionality and deciding what needs to be core
            is one of the biggest challenges for Stippl.
          </p>
          <p>
            On a high level we build a planner which makes it easy to add,
            remove and order destinations. From there for each destination
            travellers can search for accommodations, things to do and
            transportation between the destinations.
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <figcaption>
            2.0 The core planner where the traveller manages their destinations,
            nights and has a clear overview of the trip.
          </figcaption>
          <img src={headerImage} />
        </figure>

        <figure>
          <figcaption>2.1 Browse accommodations</figcaption>
          <img src={accommodations} />
        </figure>

        <figure>
          <figcaption>2.3 Browse activities</figcaption>
          <img src={todo} />
        </figure>

        <figure>
          <figcaption>2.4 Find transport route</figcaption>
          <img src={searchTransport} />
        </figure>

        <figure>
          <figcaption>2.5 View and book transport route</figcaption>
          <img src={transportDetail} />
        </figure>
      </div>
      <CallToAction
        title="There’s much more.."
        text="There is much more to show and talk about! If you are interested in more details, drop me a message."
      />
    </div>
  );
}

export default Stippl;
