import { Link } from "react-router-dom";
import Icon from "../Icons";
import CallToAction from "../CallToAction";

//images
import gnLogo from "../../img/cases/gn/logo.svg";
import headerImage from "../../img/cases/gn/dashboard.png";
import dashboard from "../../img/cases/gn/dashboard_long.png";
import forecast from "../../img/cases/gn/forecast.png";
import proposal1 from "../../img/cases/gn/proposal1.png";
import proposal2 from "../../img/cases/gn/proposal2.png";
import proposal3 from "../../img/cases/gn/proposal3.png";
import receivables from "../../img/cases/gn/receivables.png";
import recos from "../../img/cases/gn/recos.png";

function GN() {
  return (
    <div className="case-container">
      <Link to="/" className="close">
        <Icon name="close" />
      </Link>
      <div className="case-introduction">
        <div className="intro-wrapper regtech-case">
          <article>
            <div className="logo">
              <img src={gnLogo} alt="Stippl-logo" />
            </div>
            <h1>Data-driven financial insights for SMEs</h1>
            <p>
              Growth Navigator is an online tool to help SMEs manage cash, gain
              insights to solve liquidity squeeze issues and execute structural
              improvements to optimize working capital.
            </p>
          </article>
        </div>
        <div className="header-image">
          <div className="image-wrapper">
            <figure>
              <img src={headerImage} />
            </figure>
          </div>
        </div>
      </div>
      <section>
        <article>
          <h2>My role in the project</h2>
          <p>
            During this project I was responsible for the first version of the
            UX and UI, closely working together with the teams Product owner and
            researchers.
          </p>
        </article>
        <article>
          <h2>Interaction design</h2>
          <p>
            The target group for this product were CFOs, users that are used to
            Excel and love to have as much data on their screen as possible. We
            knew this was going to be a very number-heavy application, but I
            also wanted the product to stay accessible and show that numbers
            don’t always have to be boring.
          </p>
          <p>
            We decided to experiment with some navigation patterns and I came up
            with 3 patterns that would set the basis for the application:
            <ol>
              <li>
                A full master-graph page from where (almost) everything can be
                managed.
              </li>
              <li>
                Long scrollable pages with horizontal navigation between pages.
              </li>
              <li>
                A dashboard with an overview of widgets, that would open modals
                for more detailled information.
              </li>
            </ol>
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <figcaption>1.0 Master graph (wireframe)</figcaption>
          <img src={proposal1} />
        </figure>

        <figure>
          <figcaption>1.2 Long scrollable pages (wireframe)</figcaption>
          <img src={proposal2} />
        </figure>

        <figure>
          <figcaption>1.3 Dashboard with widgets (wireframe)</figcaption>
          <img src={proposal3} />
        </figure>
      </div>
      <section>
        <article>
          <h2>Decision</h2>
          <p>
            After testing with our users, we found that the dashboard view with
            widgets was most popular. This made sense because this view showed
            the most information in one screen. What was also very nice of this
            pattern is that you never had to click back more then once to get
            back to the main overview and this made the navigation in the
            application very easy and fast.
          </p>
          <p>
            We also grouped widgets together so that if you open a modal for
            lets say receivables, there is also a tabbed navigation to switch to
            payables. This made sure users didn’t have to go back and forth
            between screens for connected data.
          </p>
          <p>
            I took this navigation pattern and applied the interface design on
            top and you can see some of the results below.
          </p>
        </article>
      </section>
      <div className="image-section">
        <figure>
          <figcaption>2.0 Dashboard</figcaption>
          <img src={dashboard} />
        </figure>

        <figure>
          <figcaption>2.1 Liquidity forecast</figcaption>
          <img src={forecast} />
        </figure>

        <figure>
          <figcaption>2.2 Detailled view of all receivables</figcaption>
          <img src={receivables} />
        </figure>

        <figure>
          <figcaption>2.3 Recommendations</figcaption>
          <img src={recos} />
        </figure>
      </div>

      <CallToAction
        title="There’s much more.."
        text="There is much more to show and talk about! If you are interested in more details, drop me a message."
      />
    </div>
  );
}

export default GN;
